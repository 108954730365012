import { defineStore } from "pinia";
import { useUserData } from "~/composables/useUserData";

export const useHeaderStore = defineStore("header", () => {
  const isBurgerMenuVisible = ref(false);

  const { isLogged } = useUser();
  const { hasShop } = useUserData();

  const menuList = computed(() => {
    return [
      { title: "Правила", link: "/rules" },
      { title: "AML/KYC", link: "/aml" },
      {
        title: "Партнерам",
        link: "",
        submenu: [
          { title: "Мониторингам", link: "/monitoring" },
          {
            title: "Пользователям",
            link: isLogged.value ? "/account/referral" : "/users",
          },
        ],
      },
      { title: "Резервы", link: "/reserves" },
      { title: "Помощь", link: "/faq" },
      { title: "Магазинам", link: hasShop.value ? "/account/shop" : "/shops" },
      { title: "Новости", link: "/news" },
      { title: "Контакты", link: "/contacts" },
    ];
  });

  function toggleBurgerMenu(value?: boolean) {
    if (value !== undefined) {
      isBurgerMenuVisible.value = value;
    } else {
      isBurgerMenuVisible.value = !isBurgerMenuVisible.value;
    }
  }

  const filteredMenuList = computed(() => {
    return menuList.value.filter((item) => item.title !== "Новости");
  });

  return {
    menuList,
    toggleBurgerMenu,
    isBurgerMenuVisible,
    filteredMenuList,
  };
});
