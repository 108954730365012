<template>
  <footer :class="$style.TheFooter">
    <img
      src="/images/background/lines.svg"
      alt="lines"
      draggable="false"
      :class="$style.background"
    />
    <div :class="$style.wrapper">
      <div :class="$style.left">
        <NuxtLink to="/" :class="$style.logo">
          <SvgoIconLogo :class="$style.logotype" />
        </NuxtLink>
        <span :class="$style.txt"> Безопасный обмен криптовалют </span>
        <div :class="$style.links">
          <NuxtLink
            v-for="monitoring in monitorings"
            :key="monitoring.title"
            :to="monitoring.link"
            target="_blank"
          >
            <img
              :src="monitoring.image"
              :alt="monitoring.title"
              :title="monitoring.title"
              :class="$style.image"
            />
          </NuxtLink>
        </div>
      </div>
      <div :class="$style.right">
        <nav :class="$style.nav">
          <ul>
            <li v-for="item in menuItems" :key="item.title">
              <NuxtLink :to="item.link">
                {{ item.title }}
              </NuxtLink>
            </li>
          </ul>
        </nav>
        <ul :class="$style.social">
          <li>
            <NuxtLink to="mailto:support@kotleta.gg" :class="$style.link">
              <span>support@kotleta.gg</span>
            </NuxtLink>
          </li>
          <li>
            <NuxtLink to="tg://resolve?domain=Kotletanews" :class="$style.link">
              <SvgoIconTg :class="$style.icon" />
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const { isLogged } = useUser();

const menuItems = [
  { title: "Правила", link: "/rules" },
  { title: "AML/KYC", link: "/aml" },
  { title: "Мониторингам", link: "/monitoring" },
  {
    title: "Пользователям",
    link: isLogged.value ? "/account/referral" : "/users",
  },
  { title: "Помощь", link: "/faq" },
  { title: "Новости", link: "/news" },
  { title: "Контакты", link: "/contacts" },
  { title: "Личный кабинет", link: "/account/dashboard" },
];

const monitorings = useMonitorings();
</script>

<style lang="scss" module>
.TheFooter {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: $footer-h;
  background: $base-0;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 1px;
    width: 100%;
    min-height: calc($footer-h - 1px);
    height: 100%;
    background: $base-900;
  }
}

.TheFooter,
.TheFooter::before {
  clip-path: polygon(
    0% 0%,
    5% 0%,
    7% 7%,
    22% 7%,
    24% 0%,
    40% 0%,
    48% 18%,
    100% 18%,
    100% 100%,
    0% 100%
  );

  @include respond-to(sm) {
    clip-path: polygon(
      0% 0%,
      5% 0%,
      7% 4%,
      22% 4%,
      24% 0%,
      40% 0%,
      48% 4%,
      100% 4%,
      100% 100%,
      0% 100%
    );
  }

  @include respond-to(xs) {
    clip-path: polygon(
      0% 0%,
      5% 0%,
      7% 2%,
      22% 2%,
      24% 0%,
      40% 0%,
      48% 2%,
      100% 2%,
      100% 100%,
      0% 100%
    );
  }
}

.background {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.03;
}

.wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  padding: 5rem 2.4rem 0;
  width: 100%;
  height: 100%;

  @include respond-to(sm) {
    flex-direction: column;
    align-items: flex-start;
    gap: 60px;
    padding: 110px 95px 100px;
  }

  @include respond-to(xs) {
    flex-direction: column;
    align-items: flex-start;
    padding: 110px 16px 100px;
  }
}

.left {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  @include respond-to(lg) {
    flex-shrink: unset;
  }
}

.logo {
  display: inline-flex;
  flex-shrink: 0;
  max-width: 100%;
  margin-bottom: 2rem;
  margin-left: -2rem;
}

.logotype {
  max-width: 47rem;
  width: 100%;
  height: auto;

  @include respond-to(xs) {
    max-width: 350px;
  }
}

.txt {
  margin-bottom: 2rem;
  color: $base-500;
}

.right {
  display: flex;
  gap: 18rem;
  justify-content: space-between;

  @include respond-to(lg) {
    gap: 40px;
  }

  @include respond-to(sm) {
    flex-direction: column;
    gap: 60px;
  }
}

.nav {
  ul {
    display: block;
    column-count: 2;
    column-gap: 100px;

    @include respond-to(lg) {
      column-gap: 50px;
    }

    @include respond-to(sm) {
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }

    li {
      width: fit-content;
      font-weight: 400;
      font-size: 2rem;
      line-height: 4rem;
      white-space: nowrap;
      color: $base-0;

      @include respond-to(sm) {
        line-height: 1;
      }
    }
  }
}

.social {
  display: flex;
  gap: 1.5rem;
}

.link {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: $font-family-secondary;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 5rem;
  color: $base-0;
  letter-spacing: -0.32px;

  @include respond-to(lg) {
    font-size: 16px;
  }

  @include respond-to(sm) {
    font-size: 24px;
  }

  @include respond-to(xs) {
    font-size: 14px;
    height: 100%;
  }
}

.icon {
  width: auto;
  height: 4.5rem;
  color: black;

  @include respond-to(xs) {
    height: 30px;
  }
}

.bestchange {
  width: 18rem;
}

.links {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  max-width: 35rem;

  a {
    display: flex;
  }
}

.image {
  height: 24px;
  filter: grayscale(1);
}
</style>
